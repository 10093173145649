import React from "react"

import SiteContainer from '../components/site-container'
import SEO from "../components/seo"
import { BlogContext } from '../context/mainContext'
import { css } from '@emotion/core'
import {graphql, Link} from 'gatsby'
//import { highlight } from '../theme'
//import PostList from '../components/postList'
import checkNodeData from '../utils/checkNodeData'

import { Auth } from 'aws-amplify'
import cover from "../images/cover.jpg";

class BlogIndex extends React.Component {
  state = {
    isAdmin: false
  }
  componentDidMount() {
    Auth.currentAuthenticatedUser()
      .then(user => {
        if  (user.signInUserSession.idToken.payload['cognito:groups']) {
          const groups = user.signInUserSession.idToken.payload['cognito:groups']
          if (groups.includes("Admin")) {
            this.setState({ isAdmin: true })
          }
        }

      })
      .catch(err => console.log(err));
  }
  render() {
    // let posts = this.props.data.appsync.itemsByContentType.items.filter(post => post.published)
    // posts = posts.reverse()
    let authorImages = checkNodeData(this.props.data.allAuthorImages)
    if (authorImages) {
      authorImages = this.props.data.allAuthorImages.edges[0].node.data
    }
    const { theme, siteSlogan, siteDescription } = this.props.context
    return (
      <SiteContainer {...this.props}>
          <SEO title="SR Travel Boutique - Travel With Excellence" />
          <Link to="/" css={linkContainer}>
            <img alt='cover' css={coverContainer} src={cover} />
          </Link>
          <div css={mainContainer}>
            <h3 css={sloganContainer(theme)}>{siteSlogan}</h3>
            <section css={siteDescriptionStyle(theme)} dangerouslySetInnerHTML={{__html: siteDescription}}/>
          </div>
      </SiteContainer>
    )
  }
}


function BlogIndexWithContext(props) {
  return (
    <BlogContext.Consumer>
      {
        context => <BlogIndex {...props} context={context} />
      }
    </BlogContext.Consumer>
  )
}

export default BlogIndexWithContext

const linkContainer = css`
  box-shadow: none;
`
const coverContainer = css`
  width: 2000px;
  height: 500px;
  margin: 0px 0px 0px;
`

const sloganContainer = ({ primaryLightFontColor }) => css`
  color: ${primaryLightFontColor};
  text-align: center;
`

const siteDescriptionStyle = ({ secondaryFontColor }) => css`
  max-width: 90%;
  color: ${secondaryFontColor};
  font-size: 20px;
  line-height: 1.35;
  text-align: left;
  @media (max-width: 900px) {
    max-width: 100%;
  }
`

const mainContainer = css`
  width: 1200px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 100%;
  }
`

export const pageQuery = graphql`
  query {
    appsync {
      itemsByContentType(limit: 500, contentType: "Post") {
        items {
          content
          createdAt
          description
          id
          title
          cover_image
          published
        }
      }
    },
    file(relativePath: {eq: "logo.png"}) {
      childImageSharp {
        resize(width: 44) {
          tracedSVG
          aspectRatio
          originalName
          height
          width
          src
        }
      }
    },
    allAuthorImages {
      edges {
        node {
          data
        }
      }
    }
  }
`
